import styled from '@emotion/styled'
import React, { memo } from 'react'

export interface Props {
  hotel?: string
  title?: string
  URL?: string
  user?: string
}

export const Modal = memo(function Modal({ hotel, title, URL, user }: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <Player
        className="video-reviews"
        src={`${URL}?fs=0&modestbranding=1&rel=0&showinfo=0&enablejsapi=1`}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
      <Wrapper>
        {user ? <User>{user}</User> : null}
        {hotel ? <Hotel>{hotel}</Hotel> : null}
      </Wrapper>
    </Container>
  )
})

const Container = styled.div``

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primary};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 3.75rem;
  margin-bottom: 2.5rem;

  @media (max-width: 767px) {
    font-size: 2.25rem;
    line-height: 3rem;
    margin-bottom: 1.5rem;
  }
`

const Player = styled.iframe`
  width: 60vw;
  height: 54vh;
  border: 0;

  @media (max-width: 767px) {
    width: 90vw;
    height: 50vh;
  }
`

const Wrapper = styled.div`
  color: #000;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  line-height: 1.625rem;
  margin-top: 1.875rem;
`

const User = styled.div`
  font-size: 1rem;
`

const Hotel = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`
