import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  image?: ImageProps
  index?: number
  hotel?: string
  onClick?: (e: any) => void
  title?: string
  URL?: string
  user?: string
}

export const Video = memo(function Video({
  image,
  index,
  hotel,
  onClick,
  title,
  user,
}: Props) {
  return (
    <Container data-modal={index} onClick={onClick}>
      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
        >
          <g id="play" transform="translate(-362 -3306)">
            <circle
              cx="20"
              cy="20"
              r="20"
              transform="translate(362 3306)"
              fill="#f2c812"
            />
            <path
              d="M7.2,0l7.2,12H0Z"
              transform="translate(390 3319) rotate(90)"
              fill="#fff"
            />
          </g>
        </svg>
      </Background>
      {title ? <Title>{title}</Title> : null}
      {user ? <User>{user}</User> : null}
      {hotel ? <Hotel>{hotel}</Hotel> : null}
    </Container>
  )
})

const Container = styled.div`
  width: calc(25% - 1.125rem);
  color: #000;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  line-height: 1.625rem;
  margin-top: 3.75rem;
  position: relative;
  text-align: left;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 4;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Background = styled.div`
  background: #f4f4f4;
  border-radius: 0.375rem;
  overflow: hidden;
  margin-bottom: 1.25rem;
  padding-bottom: 56%;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 50%;
    background: linear-gradient(${rgba('#000000', 0)}, ${rgba('#000000', 0.3)});
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
  }
  > svg {
    position: absolute;
    bottom: 0.75rem;
    right: 0.75rem;
    z-index: 3;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Title = styled.div`
  color: ${({ theme }) => theme.colors.variants.primary};
  font-size: 0.8125rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
`

const User = styled.div`
  font-size: 1rem;
`

const Hotel = styled.div`
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
`
