import styled from '@emotion/styled'
import React, { memo } from 'react'

import { Item, Props as ItemProps } from './Item'

export interface Props {
  items: ItemProps[]
}

export const ZigZag = memo(function ZigZag({ items }: Props) {
  if (items.length < 1) {
    return null
  }

  return (
    <Container>
      {items.map((item, index) => (
        <Item key={index} {...item} />
      ))}
    </Container>
  )
})

const Container = styled.section`
  background: #f4f4f4;
  color: #000;
  overflow: hidden;
  padding: 9rem 0;

  @media (max-width: 767px) {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
`
