import styled from '@emotion/styled'
import { Facebook } from 'app/components/Landing/Facebook'
import { Features } from 'app/components/Landing/Features'
import { Footer } from 'app/components/Landing/Footer'
import { Header } from 'app/components/Landing/Header'
import { HeroLanding } from 'app/components/Landing/HeroLanding'
import { VideoReviews } from 'app/components/Landing/VideoReviews'
import { ZigZag } from 'app/components/Landing/ZigZag'
import { SEO } from 'app/components/SEO'
import { LandingForm } from 'app/containers/LandingForm'
import {
  LandingFormSenderBackend,
  LandingFormSenderBackendConfiguration,
  LandingFormSenderSlack,
  LandingFormSenderSlackConfiguration,
} from 'app/utils/LandingFormSender'
import { isLeft } from 'fp-ts/lib/These'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  landingFormSenderBackendConfiguration: LandingFormSenderBackendConfiguration
  landingFormSenderSlackConfiguration: LandingFormSenderSlackConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function LandingPageTemplate({
  pageContext,
}: PageProps<void, PageContext>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      LandingFormSenderBackend(
        pageContext.landingFormSenderBackendConfiguration,
      ),
    [pageContext.landingFormSenderBackendConfiguration],
  )
  const sendFormToSlack = useMemo(
    () =>
      LandingFormSenderSlack(pageContext.landingFormSenderSlackConfiguration),
    [pageContext.landingFormSenderSlackConfiguration],
  )

  const conversionID = `pixel-${Date.now()}`

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.facebookProps ? (
        <Facebook conversionID={conversionID} {...context.facebookProps} />
      ) : null}
      {context.headerProps ? <Header {...context.headerProps} /> : null}
      {context.heroProps ? <HeroLanding {...context.heroProps} /> : null}
      {context.featuresProps ? <Features {...context.featuresProps} /> : null}
      {context.zigZagProps ? <ZigZag {...context.zigZagProps} /> : null}
      {context.videoProps ? <VideoReviews {...context.videoProps} /> : null}
      {context.formProps ? (
        <LandingForm
          conversionID={conversionID}
          onLandingFormSendToBackend={async (data) => {
            const result = await sendFormToBackend(data)
            await sendFormToSlack({
              data,
              backendError: isLeft(result) ? result.left : undefined,
            })
            return result
          }}
          {...context.formProps}
        />
      ) : null}
      {context.footerProps ? <Footer {...context.footerProps} /> : null}
    </Container>
  )
})

const Container = styled.main`
  margin-top: -110px;

  @media (max-width: 767px) {
    margin-top: -60px;
  }
`
