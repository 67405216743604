import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { FlexBox } from 'app/components/FlexBox'
import { Button } from 'app/components/Landing/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  text?: string
  image?: ImageProps
  languageCode: string
  title?: string
}

export const Item = memo(function Item({
  text,
  image,
  languageCode,
  title,
}: Props) {
  if (!text || !title) {
    return null
  }

  return (
    <Container dial={4} row wrap>
      <LeftSide>
        {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
        {text ? (
          <Description dangerouslySetInnerHTML={{ __html: text }} />
        ) : null}
        <CTA
          label={useVocabularyData(
            'request-free-demo-no-obligation',
            languageCode,
          )}
        />
      </LeftSide>
      <RightSide>
        {image ? (
          <Background>
            <LazyLoadComponent>
              <Image className="zig-zag-image" {...image} />
            </LazyLoadComponent>
          </Background>
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  color: #000;
  margin-top: 5.625rem;
  &:first-of-type {
    margin-top: 0;
  }
  &:nth-of-type(even) {
    > div {
      &:first-of-type {
        order: 2;
        padding-right: 10vw;
        padding-left: 3.75rem;

        @media (max-width: 1199px) {
          padding-right: 4.167vw;
        }

        @media (max-width: 767px) {
          padding-left: 4.167vw;
        }
      }
    }
    .zig-zag-image {
      margin-right: auto;
      margin-left: 0;
      transform: translateX(-2.5rem);

      @media (max-width: 767px) {
        transform: none;
      }
    }
  }
`

const LeftSide = styled.div`
  width: 50%;
  padding-right: 3.75rem;
  padding-left: 10vw;

  @media (max-width: 1199px) {
    padding-left: 4.167vw;
  }

  @media (max-width: 767px) {
    width: 100%;
    order: 2;
    padding-right: 4.167vw;
  }
`

const Title = styled.h2`
  max-width: 500;
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 3.75rem;
  margin-bottom: 0.875rem;

  @media (max-width: 767px) {
    font-size: 2.875rem;
    line-height: 3.4375rem;
  }
`

const Description = styled.div`
  max-width: 500;
  font-size: 1rem;
  line-height: 1.625rem;
`

const CTA = styled(Button)``

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Background = styled.div`
  display: block;
  width: auto;
  height: 38.194vw;
  max-height: 550px;
  margin-left: auto;
  position: relative;
  transform: translateX(2.5rem);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
    height: auto;
    max-height: none;
    margin-bottom: 1.875rem;
    padding: 0 4.167vw;
    transform: none;
  }
`
