/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-explicit-any */

import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo, useState } from 'react'

import { Modal } from './Modal'
import { Props as VideoProps, Video } from './Video'

export interface Props {
  title?: string
  video: VideoProps[]
}

export const VideoReviews = memo(function VideoReviews({
  title,
  video,
}: Props) {
  const [modalOpen, setModal] = useState(null)
  const openModal = (e: any) => {
    const {
      target: {
        dataset: { modal },
      },
    } = e

    if (modal) setModal(modal)
  }

  const stopVideos = function () {
    const videos = document.querySelectorAll('.video-reviews')
    Array.prototype.forEach.call(videos, function (video) {
      video.contentWindow.postMessage(
        '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
        '*',
      )
    })
  }

  if (!video) {
    return null
  }

  return (
    <Container>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="144"
        height="40"
        viewBox="0 0 144 40"
      >
        <g transform="translate(-648 -3686)">
          <path
            d="M56.859,64.883l-11.88-6.246L33.1,64.883a1,1,0,0,1-1.452-1.055L33.914,50.6,24.3,41.23a1,1,0,0,1,.554-1.706l13.285-1.93,5.939-12.037a1,1,0,0,1,1.794,0l5.94,12.037L65.1,39.524a1,1,0,0,1,.554,1.706L56.04,50.6l2.269,13.228a1,1,0,0,1-1.451,1.055"
            transform="translate(675 3661)"
          />
          <path
            d="M40.429,44.941l-5.94-3.123-5.941,3.123a.5.5,0,0,1-.726-.527L28.957,37.8l-4.806-4.685a.5.5,0,0,1,.277-.853l6.642-.965,2.97-6.019a.5.5,0,0,1,.9,0l2.97,6.019,6.642.965a.5.5,0,0,1,.277.853L40.02,37.8l1.134,6.614a.5.5,0,0,1-.725.527"
            transform="translate(747.022 3671)"
          />
          <path
            d="M40.429,44.941l-5.94-3.123-5.941,3.123a.5.5,0,0,1-.726-.527L28.957,37.8l-4.806-4.685a.5.5,0,0,1,.277-.853l6.642-.965,2.97-6.019a.5.5,0,0,1,.9,0l2.97,6.019,6.642.965a.5.5,0,0,1,.277.853L40.02,37.8l1.134,6.614a.5.5,0,0,1-.725.527"
            transform="translate(624 3671)"
          />
        </g>
      </svg>
      {title ? <Title>{title}</Title> : null}
      <Videos row wrap space="between">
        {video.map((item, index) => (
          <Video
            index={index}
            onClick={(e: any) => {
              openModal(e)
            }}
            key={index}
            {...item}
          />
        ))}
      </Videos>
      {video.map((item, index) => (
        <ModalContainer
          className={modalOpen === `${index}` ? 'open' : undefined}
          dial={5}
          key={index}
        >
          <ModalClose
            onClick={() => {
              setModal(null)
              stopVideos()
            }}
          />
          <Modal key={index} {...item} />
        </ModalContainer>
      ))}
    </Container>
  )
})

const Container = styled.section`
  padding: 7rem 10vw 7.5rem;
  text-align: center;
  > svg {
    margin: 0 auto 1.875rem;
    path {
      fill: ${({ theme }) => theme.colors.variants.primary};
    }
  }

  @media (max-width: 1199px) {
    padding-right: 4.167vw;
    padding-left: 4.167vw;
  }

  @media (max-width: 767px) {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
`

const Title = styled.h2`
  max-width: 600px;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 3.75rem;
  margin: auto;

  @media (max-width: 767px) {
    font-size: 2.875rem;
    line-height: 3.4375rem;
  }
`

const Videos = styled(FlexBox)``

const ModalContainer = styled(FlexBox)`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.variants.base};
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  transform: translateY(2vh);
  transition: 0.2s ease-in-out;
  &.open {
    opacity: 1;
    transform: translateY(0);
    visibility: visible;
    transition: 0.6s ease-in-out;
    z-index: 9999;
  }
`

const ModalClose = styled.div`
  width: 3.75rem;
  height: 3.75rem;
  background: #000;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 3rem;
  right: 3rem;
  z-index: 2;
  &:before,
  &:after {
    content: '';
    width: 1.5rem;
    height: 2px;
    background: ${({ theme }) => theme.colors.variants.base};
    position: absolute;
    top: 1.8125rem;
    left: 1.125rem;
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }

  @media (max-width: 767px) {
    width: 3rem;
    height: 3rem;
    top: 1.875rem;
    right: 1.875rem;
    &:before,
    &:after {
      top: 1.375rem;
      left: 0.75rem;
    }
  }
`
