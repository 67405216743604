import styled from '@emotion/styled'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Button } from 'app/components/Landing/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  description?: string
  image?: ImageProps
  languageCode: string
  title?: string
}

export const HeroLanding = memo(function HeroLanding({
  description,
  image,
  languageCode,
  title,
}: Props) {
  const onClick = () => {
    window.scroll({
      top: window.innerHeight + 1,
      left: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Container>
      {image ? (
        <LazyLoadComponent>
          <Image className="hero-image" {...image} />
        </LazyLoadComponent>
      ) : null}
      <Wrapper>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="147.913"
          height="28.156"
          viewBox="0 0 147.913 28.156"
        >
          <g transform="translate(-635.246 -85.865)">
            <path d="M650.45,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
            <path d="M671.473,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
            <path d="M692.5,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
            <path d="M713.52,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
            <path d="M734.543,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
            <path d="M755.566,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
            <path d="M776.589,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
          </g>
        </svg>
        {title ? <Claim>{title}</Claim> : null}
        {description ? (
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        ) : null}
        <CTA label={useVocabularyData('find-out-more', languageCode)} />
      </Wrapper>
      <Scroll onClick={onClick}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16.236"
          height="69.516"
          viewBox="0 0 16.236 69.516"
        >
          <g transform="translate(593.544 733.242) rotate(180)">
            <path d="M585.427,663.725l-8.118,8.7h16.236Z" />
            <path d="M577.309,702.833h16.236l-8.118-8.7Z" />
            <path d="M577.309,733.242h16.236l-8.118-8.7Z" />
          </g>
        </svg>
      </Scroll>
    </Container>
  )
})

const Container = styled.section`
  height: 100vh;
  max-height: -webkit-fill-available;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to right,
      ${rgba('#000000', 1)} 15%,
      ${rgba('#000000', 0)}
    );
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }

  .hero-image {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  @media (max-width: 1199px) {
    height: auto;
    max-height: none;
  }
`

const Wrapper = styled.div`
  color: ${({ theme }) => theme.colors.variants.base};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  position: absolute;
  top: 50%;
  right: 10vw;
  left: 10vw;
  transform: translateY(-50%);
  z-index: 2;
  > svg {
    fill: ${({ theme }) => theme.colors.variants.primary};
    margin-bottom: 1.875rem;
  }

  @media (max-width: 1199px) {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    padding: 9rem 4.167vw 5.625rem;
    transform: none;
  }
`

const Claim = styled.h1`
  max-width: 825px;
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 4.1875rem;
  margin-bottom: 0.875rem;

  @media (max-width: 767px) {
    font-size: 2.875rem;
    line-height: 3.4375rem;
  }
`

const Description = styled.div`
  max-width: 760px;
  font-size: 1.1875rem;
  line-height: 1.875rem;
`

const CTA = styled(Button)``

const Scroll = styled.div`
  cursor: pointer;
  position: absolute;
  bottom: 3.25rem;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  > svg {
    fill: ${({ theme }) => theme.colors.variants.base};
  }

  @media (max-width: 767px) {
    display: none;
  }
`
