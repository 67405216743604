import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'

import { Feature, Props as FeatureProps } from './Feature'

export interface Props {
  description?: string
  title?: string
  features: FeatureProps[]
}

export const Features = memo(function Features({
  description,
  title,
  features,
}: Props) {
  if (features.length < 1) {
    return null
  }

  return (
    <Container>
      <Separator
        xmlns="http://www.w3.org/2000/Separator"
        width="147.913"
        height="28.156"
        viewBox="0 0 147.913 28.156"
      >
        <g transform="translate(-635.246 -85.865)">
          <path d="M650.45,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
          <path d="M671.473,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
          <path d="M692.5,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
          <path d="M713.52,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
          <path d="M734.543,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
          <path d="M755.566,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
          <path d="M776.589,85.865l-15.2,28.156h6.194l15.58-28.156Z" />
        </g>
      </Separator>
      {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
      {description ? (
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
      <List row wrap space="between" stretch>
        {features.map((item, index) => (
          <Feature key={index} {...item} />
        ))}
      </List>
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.primary};
  color: #000;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  padding: 7.5rem 10vw;
  text-align: center;

  @media (max-width: 1199px) {
    padding-right: 4.167vw;
    padding-left: 4.167vw;
  }

  @media (max-width: 767px) {
    padding-top: 5.625rem;
    padding-bottom: 5.625rem;
  }
`

const Separator = styled.svg`
  fill: ${({ theme }) => theme.colors.variants.base};
  opacity: 0.2;
  margin: 0 auto 1.875rem;
`

const Title = styled.h2`
  font-size: 3.25rem;
  font-weight: 700;
  line-height: 3.75rem;
  margin: 0 auto 1.375rem;

  @media (max-width: 767px) {
    font-size: 2.875rem;
    line-height: 3.4375rem;
  }
`

const Description = styled.div`
  max-width: 750px;
  font-size: 1rem;
  line-height: 1.625rem;
  margin: auto;
`

const List = styled(FlexBox)`
  margin-top: 3.75rem;
`
