import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import { Button } from 'app/components/Landing/Button'
import { useVocabularyData } from 'app/utils/vocabulary'
import { rgba } from 'emotion-rgba'
import React, { memo, useEffect, useState } from 'react'

declare global {
  interface Window {
    dataLayer: any
  }
}

export interface Props {
  phone?: string
  languageCode: string
}

export const Header = memo(function Header({ languageCode, phone }: Props) {
  const [scroll, setScroll] = useState(false)

  useEffect(() => {
    const onScroll = () => {
      setScroll(window.scrollY > window.innerHeight)
    }

    window.addEventListener('scroll', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Container
      className={scroll ? 'fixed' : undefined}
      dial={4}
      row
      tag="section"
    >
      <Logo
        src={scroll ? '/logo.png' : '/logo-negativo.png'}
        width="199"
        height="33"
        alt="Simple Booking"
      />
      <LogoBeSafe
        src={scroll ? '/logo-besafe-negativo.png' : '/logo-besafe.png'}
        width="168"
        height="33"
        alt="BeSafe Rate"
      />
      {phone ? (
        <Phone
          className="header-phone"
          href={`tel:${phone}`}
          onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'buttonClick',
              category: 'Phone Number',
              action: 'Click',
              label: document.title,
            })
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.969"
            height="16"
            viewBox="0 0 15.969 16"
          >
            <path
              d="M18.08,13.979v2.409A1.605,1.605,0,0,1,16.48,18c-.051,0-.1,0-.151-.006A15.888,15.888,0,0,1,9.4,15.529a15.653,15.653,0,0,1-4.817-4.817A15.888,15.888,0,0,1,2.118,3.75,1.606,1.606,0,0,1,3.573,2.006C3.621,2,3.669,2,3.716,2H6.125A1.605,1.605,0,0,1,7.731,3.381a10.3,10.3,0,0,0,.562,2.256,1.605,1.605,0,0,1-.361,1.694l-1.02,1.02a12.846,12.846,0,0,0,4.817,4.817l1.02-1.02a1.605,1.605,0,0,1,1.694-.361,10.3,10.3,0,0,0,2.256.562A1.606,1.606,0,0,1,18.08,13.979Z"
              transform="translate(-2.112 -2)"
            />
          </svg>
          {phone}
        </Phone>
      ) : null}
      <CTA label={useVocabularyData('request-your-free-demo', languageCode)} />
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: 100%;
  padding: 2.25rem 3.75rem 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  &.fixed {
    animation: fadeInTop 0.3s;
    background: ${({ theme }) => theme.colors.variants.base};
    box-shadow: ${rgba('#000', 0.1)} 0 3px 20px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    position: fixed;
    top: 0;
    left: 0;
    .header-phone {
      color: #000;
    }
  }

  @media (max-width: 1199px) {
    padding-right: 4.167vw;
    padding-left: 4.167vw;
  }

  @media (max-width: 767px) {
    &.fixed {
      position: absolute;
    }
  }

  @keyframes fadeInTop {
    0% {
      top: -4.375rem;
    }
    100% {
      top: 0;
    }
  }
`

const Logo = styled.img``

const LogoBeSafe = styled.img`
  margin-left: 2rem;

  @media (max-width: 574px) {
    margin-left: 0;
    position: absolute;
    top: 74px;
    left: 4.167vw;
  }
`

const Phone = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.base};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  font-weight: 700;
  letter-spacing: 0.0625rem;
  line-height: 1.5625rem;
  margin-right: 2.5rem;
  margin-left: auto;
  &:hover {
    text-decoration: underline;
  }
  svg {
    fill: ${({ theme }) => theme.colors.variants.primary};
    margin-right: 0.75rem;
  }

  @media (max-width: 767px) {
    display: none;
  }
`

const CTA = styled(Button)`
  margin-top: 0;

  @media (max-width: 767px) {
    display: none;
  }
`
