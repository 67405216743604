import styled from '@emotion/styled'
import { theme } from 'app/theme'
import { rgba } from 'emotion-rgba'
import React, { memo } from 'react'
import { Link } from 'react-scroll'

interface Props {
  languageCode: string
  phone?: string
}

export const Toolbars = memo(function Toolbars({ phone }: Props) {
  return (
    <Container>
      {phone ? (
        <Item
          href={`tel:${phone}`}
          onClick={() => {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              event: 'buttonClick',
              category: 'Phone Number',
              action: 'Click',
              label: document.title,
            })
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15.969"
            height="16"
            viewBox="0 0 15.969 16"
          >
            <path
              d="M18.08,13.979v2.409A1.605,1.605,0,0,1,16.48,18c-.051,0-.1,0-.151-.006A15.888,15.888,0,0,1,9.4,15.529a15.653,15.653,0,0,1-4.817-4.817A15.888,15.888,0,0,1,2.118,3.75,1.606,1.606,0,0,1,3.573,2.006C3.621,2,3.669,2,3.716,2H6.125A1.605,1.605,0,0,1,7.731,3.381a10.3,10.3,0,0,0,.562,2.256,1.605,1.605,0,0,1-.361,1.694l-1.02,1.02a12.846,12.846,0,0,0,4.817,4.817l1.02-1.02a1.605,1.605,0,0,1,1.694-.361,10.3,10.3,0,0,0,2.256.562A1.606,1.606,0,0,1,18.08,13.979Z"
              transform="translate(-2.112 -2)"
            />
          </svg>
        </Item>
      ) : null}
      <CTA to="section-form">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26.595"
          height="20"
          viewBox="0 0 26.595 20"
        >
          <g transform="translate(-9.11 -19.24)">
            <path
              d="M25.771,29.336,37.091,19.9a3.211,3.211,0,0,0-1.948-.66H15.158a3.211,3.211,0,0,0-1.948.66l11.327,9.436A.962.962,0,0,0,25.771,29.336Z"
              transform="translate(-2.746)"
            />
            <path
              d="M35.523,26.08,24.264,35.292a2.931,2.931,0,0,1-3.711,0L9.292,26.08a3.234,3.234,0,0,0-.182,1.065v13.4a3.285,3.285,0,0,0,3.285,3.278H32.421a3.285,3.285,0,0,0,3.285-3.278v-13.4A3.234,3.234,0,0,0,35.523,26.08Z"
              transform="translate(0 -4.582)"
            />
          </g>
        </svg>
      </CTA>
    </Container>
  )
})

const Container = styled.aside`
  position: absolute;
  top: 4.167vw;
  right: 4.167vw;
  z-index: 50;
  transition: 0.3s ease-out;
  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background: ${({ theme }) => theme.colors.variants.primary};
    border-radius: 50%;
    svg {
      width: auto;
      height: 0.9375rem;
      fill: #000;
    }
  }
`

const Item = styled.a`
  margin-bottom: 0.5625rem;
`

const CTA = styled(Link)``
