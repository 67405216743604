import axios from 'axios'
import { sha256 } from 'js-sha256'
import { memo, useEffect } from 'react'

export interface Props {
  conversionID?: string
  pageTitle: string
  pixelID: string
}

export const Facebook = memo(function Facebook({
  conversionID,
  pageTitle,
  pixelID,
}: Props) {
  if (!pixelID) {
    return null
  }

  useEffect(() => {
    axios
      .get('https://geolocation-db.com/json/')
      .then((res) => {
        const geoData = res.data

        axios
          .post(
            `https://graph.facebook.com/v12.0/${pixelID}/events?access_token=${process.env.FACEBOOK_API_TOKEN}`,
            {
              data: [
                {
                  event_name: 'ViewContent',
                  event_id: conversionID,
                  event_time: Math.round(new Date().getTime() / 1000),
                  action_source: 'website',
                  event_source_url: window.location.pathname,
                  user_data: {
                    client_ip_address: `${geoData.IPv4 || null}`,
                    client_user_agent: navigator.userAgent,
                    country: [sha256(geoData.country_code) || null],
                    ct: [
                      sha256(
                        geoData.city
                          .replace(/[^A-Z0-9]+/gi, '-')
                          .toLocaleLowerCase(),
                      ) || null,
                    ],
                    zp: [sha256(geoData.postal) || null],
                  },
                },
              ],
            },
          )
          .then((response) => response)
          .catch(() => {
            return false
          })
      })
      .catch(function () {
        return false
      })

    const script = document.createElement('script')
    script.async = true

    script.innerHTML = `!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${pixelID}');
fbq('track', 'PageView');
fbq('track', 'ViewContent', { content_name: '${pageTitle}' }, {eventID: '${conversionID}'});`

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [conversionID, pageTitle, pixelID])

  return null
})
