import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Link } from 'react-scroll'

export interface Props {
  className?: string
  label: string
  targetScroll?: string
}

export const Button = memo(function Button({
  className,
  label = 'Button',
  targetScroll = 'section-form',
}: Props) {
  return (
    <Container className={className} to={targetScroll} offset={-92}>
      {label}
    </Container>
  )
})

const Container = styled(Link)`
  display: inline-block;
  background: ${({ theme }) => theme.colors.variants.primary};
  border: 0;
  border-radius: 1.875rem;
  color: #000000;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.625rem;
  margin-top: 1.875rem;
  padding: 1.125rem 1.875rem 1rem;
  transition: 0.3s ease-in-out;
  &:hover {
    background: ${({ theme }) =>
      theme.colors.getHoverColor(theme.colors.variants.primary, 0.15)};
  }
`
