import styled from '@emotion/styled'
import { FlexBox } from 'app/components/FlexBox'
import React, { memo } from 'react'

export interface Props {
  text?: string
  icon?: string
  title?: string
}

export const Feature = memo(function Feature({ text, icon, title }: Props) {
  if (!text || !title) {
    return null
  }

  return (
    <Container row>
      <LeftSide>
        {icon ? <Icon style={{ backgroundImage: `url(${icon})` }} /> : null}
      </LeftSide>
      <RightSide>
        {title ? <Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
        {text ? (
          <Description dangerouslySetInnerHTML={{ __html: text }} />
        ) : null}
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  width: calc(50% - 0.375rem);
  background: ${({ theme }) => theme.colors.variants.base};
  border-radius: 0.75rem;
  margin-top: 0.75rem;
  padding: 2.125rem 4.75rem 2.6875rem 2.5rem;
  text-align: left;

  @media (max-width: 767px) {
    width: 100%;
    padding: 1.5rem 1.5rem 1.875rem;
  }
`

const LeftSide = styled.div``

const Icon = styled.div`
  width: 3.25rem;
  height: 3.875rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1.875rem;
`

const RightSide = styled.div``

const Title = styled.h2`
  font-size: 1.1875rem;
  font-weight: 700;
  line-height: 1.625rem;
  margin-bottom: 0.9375rem;
`

const Description = styled.div`
  color: #585858;
  font-size: 1rem;
  line-height: 1.625rem;
`
